<template>
  <div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h4>
                     الملاحظات الصحية
                </h4>
            </div>
            <div class="card-body">
                <button class="btn btn-sm btn-warning" style="border-radius: 0px" @click="print()">
                    <i class="fa fa-print"></i>
                    طباعة الصفحة
                </button>
                <select v-model="selected_classroom" style="border-radius: 0px; border: solid 1px #eee">
                    <option value="all">-- اختر الفصل --</option>
                    <option v-for="c in classrooms" :key="c">{{ c }}</option>
                </select>
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-sm table-bordered table-striped" id="table">
                        <thead>
                            <tr>
                                <th>
                                    <a href="javascript:;" @click="ordertable('name')">الطالب <i class="fa fa-sort"></i></a>
                                </th>
                                <th>
                                    <a href="javascript:;" @click="ordertable('classname')">الفصل <i class="fa fa-sort"></i></a>
                                </th>
                                <th>
                                    <a href="javascript:;" @click="ordertable('content')">الحالة <i class="fa fa-sort"></i></a>
                                </th>
                                <th>
                                    <a href="javascript:;" @click="ordertable('date')">التاريخ <i class="fa fa-sort"></i></a>
                                </th>
                                <th>
                                    مقبول
                                </th>
                                <th>
                                    بواسطة	
                                </th>
                                <th class="hidemonprint">
                                    حذف
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="rate in rates">
                                <tr :key="rate._id" v-if="selected_classroom == 'all' || selected_classroom == rate.classname + ' - ' + rate.classroom">
                                    <td>
                                        {{ rate.name }}
                                    </td>
                                    <td>
                                        {{ rate.classname + ' - ' + rate.classroom }}
                                    </td>
                                    <td>
                                        {{ rate.content }}
                                        <a href="javascript:;" @click="editRate(rate)"><i class="fa fa-edit"></i></a>
                                    </td>
                                    <td>
                                        {{ rate.date }}
                                    </td>
                                    <td>
                                        <span v-if="rate.status" class="text-success">
                                            <i class="fa fa-check"></i> مقبول
                                        </span>
                                        <button @click="accept(rate._id)" v-if="!rate.status" class="btn-success btn-sm btn">
                                            <i class="fa fa-check"></i> قبول
                                        </button>
                                    </td>
                                    <td>
                                        {{ rate.from == 'admin' ? 'المسؤول' : 'ولي الامر' }}
                                    </td>
                                    <td class="hidemonprint">
                                        <button class="btn btn-danger btn-sm" @click="deleteHealth(rate._id)">
                                            <i class="fa fa-trash"></i> حذف
                                        </button>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { VBModal, BModal, BFormInput, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
    components: {
        // Layouts
        BDropdown, BDropdownItem,
        vSelect,
        BModal,BFormInput},
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            rates: [],
            user: JSON.parse(localStorage.getItem('user')),
            selected_classroom: 'all',
            classrooms: [],
            xa: -1,
            xb: 1,
            last_sort: "f",
        }
    },
    created(){
        if(!checkPer("students|rates")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        g.getrates();
    },
    methods: {
        ordertable(m){
            var x = this.rates, g = this;
            if(g.last_sort == m){
                g.xa = g.xa == 1 ? -1 : 1;
                g.xb = g.xb == -1 ? 1 : -1;
            }
            g.last_sort = m;
            function compare( a, b ) {
                if ( a[m] < b[m] ){
                    return g.xa;
                }
                if ( a[m] > b[m] ){
                    return g.xb;
                }
                return 0;
            }
            x.sort(compare);
            this.rates = []
            var g = this;
            setTimeout(() => {
                g.rates = JSON.parse(JSON.stringify(x))
            }, 100);
        },
        print(){
            var g = this;
            var divToPrint=document.getElementById("table");
            var newWin= window.open("");
            newWin.document.write(`
            <style>
            html, body{
                direction: rtl
            }
            .hidemonprint{
                display:none;   
            }
            table, td, th {
            border: 1px solid;
            }

            table {
            width: 100%;
            border-collapse: collapse;
            }
            </style>
            ` + divToPrint.outerHTML);
            newWin.document.close()
            newWin.print();
        },
        accept(id){
            var g = this;
            $.post(api + '/user/students/healths/accept', {
                jwt: this.user.jwt,
                id: id
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.getrates()
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        getrates(){
            var g = this;
            $.post(api + '/user/students/healths', {
                jwt: this.user.jwt,
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.rates = r.response
                    g.rates.forEach(function(a){
                        if(!g.classrooms.includes(a.classname + ' - ' + a.classroom)){
                            g.classrooms.push(a.classname + ' - ' + a.classroom)
                        }
                    })
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        deleteHealth(id){
            if(confirm("متأكد من حذف الحالة الصحية؟")){
                var g = this;
                $.post(api + '/user/students/health/delete', {
                    jwt: this.user.jwt,
                    id: id
                }).then(function(r){
                    g.getrates()
                }).fail(function(){
                    alert("حدث خطأ")
                })
            }
        },
        editRate(rate){
            var g = this;
            var t = prompt("الحالة", rate.content);
            if(t!= "" && t){
                $.post(api + '/user/students/health/edit', {
                    jwt: g.user.jwt,
                    id: rate._id,
                    content: t
                }).then(function(r){
                    g.getrates()
                }).fail(function(){
                    alert("حدث خطأ")
                })
            }
        }
    }
}
</script>

<style>
@import url(https://cdnjs.cloudflare.com/ajax/libs/vue-select/3.10.0/vue-select.css);
#vs3__listbox{
    max-height: 150px !important;
    overflow: hidden !important;
}
</style>